import React, { useEffect, useState } from "react";
import left from "../assets/left.png";
import right from "../assets/right.png";
import img from "../assets/htpf.png";
import wc from "../assets/wcF.png";
// 
import pf from "../assets/prize funds new.png";
import guide from "../assets/gggg.png";
// import hands from '../assets/hands-12.png';
import '../Pages/Home.css'
//import swal from "sweetalert";
import ConnectWallet from "./Modals/ConnectWallet";
const Section3 = ({ indexxFortuneLottery }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [lotteryId, setLotteryId] = useState(62);
    const [winningNumber, setWinningNumber] = useState();
    const [imageURL, setImageURL] = useState({
        number1: require("../assets/" + 1 + ".png"),
        number2: require("../assets/" + 9 + ".png"),
        number3: require("../assets/" + 2 + ".png"),
        number4: require("../assets/" + 3 + ".png"),
        number5: require("../assets/" + 4 + ".png"),
        number6: require("../assets/" + 0 + ".png"),
    })
    const [numOfPlayer,setNumOfPlayer]=useState(400);

    const [prizePot, setPrizePot] = useState(101899);
    const [winningDetails, setWinningDetails] = useState({
        count: [202, 109, 22, 20, 0, 0],
        amountInINEX: [294, 441, 735, 1471, 2941, 5882],
        amountInUSD: [1380, 1380, 1380, 1380, 1380, 0],
    });
    const loadInfo = async () => {
        // console.log("in Loadinfo: ");
        let lotteryId = localStorage.getItem('lotteryId');
        // console.log("Load info: ", lotteryId);
        try {

            // console.log(lotteryId);

            let lastLotteryId = lotteryId;
            let data = await indexxFortuneLottery.viewLottery(lotteryId);
            // console.log(data[0]);
            // if status of cur lottery is not claimable
            // if (parseInt(data[0].toString(), 10) !== 3)
            //     lastLotteryId = lotteryId - 1;
            // setLotteryId(lastLotteryId);
            // displaying the latest claimable lottery's winning number
            // data = await indexxFortuneLottery.viewLottery(lastLotteryId);
            // console.log(data[12]);
            // setWinningNumber(data[12].toString().substring(1).split('').reverse().join(''));
            // console.log("Winning num: ", winningNumber);
            // to display winning number
            if (lotteryId > 0 && parseInt(data[0].toString(), 10) !== 3)
                lastLotteryId = lotteryId - 1;
            if (lastLotteryId <= 0) {
                setLotteryId(lastLotteryId);
                setWinningNumber("000000");
                console.log("Winning num: ", winningNumber);
                setPrizePot(0);
                setNumOfPlayer(0);
            } else {
                setLotteryId(lastLotteryId);
                // displaying the latest claimable lottery's winning number
                data = await indexxFortuneLottery.viewLottery(lastLotteryId);
                console.log(parseInt(data[11].toString(),10)/1e18.toFixed(2));
                let prizePot=parseInt(data[11].toString(),10)*0.2/1e18;
                prizePot=prizePot.toFixed(2);
                setPrizePot(prizePot)
                setWinningNumber(data[12].toString().substring(1).split('').reverse().join(''));
                setNumOfPlayer(parseInt(data[10].toString(),10)-parseInt(data[9].toString(),10))

                // console.log("Winning num: ", winningNumber);
            }
            setImageURL({
                number1: require("../assets/" + winningNumber[0] + ".png"),
                number2: require("../assets/" + winningNumber[1] + ".png"),
                number3: require("../assets/" + winningNumber[2] + ".png"),
                number4: require("../assets/" + winningNumber[3] + ".png"),
                number5: require("../assets/" + winningNumber[4] + ".png"),
                number6: require("../assets/" + winningNumber[5] + ".png"),

            })
            // console.log(" price per bracket", data[7][0]);
            // console.log(" count per bracket", data[8][0]);
            // each bracket details
            let countPerBracket = [], pricePerBracketInINEX = [], pricePerBracketInUSD = [], price = 0;
            for (let index = 0; index < 6; index++) {
                countPerBracket.push(parseInt(data[8][index].toString(), 10));
                price = parseInt(data[7][index].toString(), 10);
                // console.log(price);
                price /= 1e18;
                
                pricePerBracketInINEX.push(price.toFixed(2));
                price *= 0.2;
                price = price.toFixed(2);
                pricePerBracketInUSD.push(price);

            }
            setWinningDetails({
                count: countPerBracket,
                amountInINEX: pricePerBracketInINEX,
                amountInUSD: pricePerBracketInUSD
            })
        } catch (error) {
            console.log("error", error);
        }
    }
    useEffect(() => {
        if (!indexxFortuneLottery.signer) {
            // setIsModalOpen(true);
        } else {
            loadInfo();
        }

    })


    return (
        <>
            <div className="flex justify-center">
                <div>
                    <div class="container py-1 flex flex-col items-center pt-9 content-center md:pt-32 pt-16 pb-5 md:w-full ">
                        <div class='text-hover md:text-5xl text-xl py-9 text-center'>Finished Rounds</div>
                        <div className="background2">
                            <div class="mx-auto grid bg-white  grid-cols-6 md:gap-x-12 border border-grey border-opacity-30 rounded-sm pb-5 md:mb-96 mb-40 md:w-[964px] w-[350px]">
                                <div class="col-start-1 col-span-1 font-medium px-4 pt-4  text-grey md:text-lg text-xs ">Round {lotteryId}</div>
                                <div class="col-start-2 col-span-full font-normal px-4 pt-4 flex justify-end ">
                                    <img src={left} alt='tickets' class='inline md:w-[20px] w-[13px] h-[10px] md:h-[15px] mr-1' /> <img src={right} alt='tickets' class='inline md:w-[20px] w-[13px] h-[10px] md:h-[15px] mr-1' />
                                </div>
                                <div class="col-start-1 col-span-3 font-normal px-4 pt-2 text-grey md:text-sm text-[10px] pb-2"></div>
                                <div class="col-start-1 col-span-full md:font-normal font-medium px-4 pt-4 text-grey  md:text-lg text-xs border-t border-grey border-opacity-30">Winning Number</div>
                                {
                                    // indexxFortuneLottery.signer ? (
                                    <div class="col-start-1 col-span-full font-medium  pt-4  text-whitebg  md:text-lg text-[10px] pb-4 border-b border-grey border-opacity-30 flex justify-center">
                                        <span class='w-[100px] px-1'><img alt="img" src={imageURL.number1} /></span>
                                        <span class='w-[100px]'><img alt="img" src={imageURL.number2} /></span>
                                        <span class='w-[100px] px-1'><img alt="img" src={imageURL.number3} /></span>
                                        <span class='w-[100px]'><img alt="img" src={imageURL.number4} /></span>
                                        <span class='w-[100px] px-1'><img alt="img" src={imageURL.number5} /></span>
                                        <span class='w-[100px]'><img alt="img" src={imageURL.number6} /></span>

                                    </div>

                                }


                                <div class=" col-start-1 col-span-6 font-normal  pt-4  text-grey md:text-sm text-[9px] flex justify-center">Match the winning number in the same order to share prizes.</div>
                                <div class="col-start-1 col-span-2 font-medium md:pl-4 pl-4 pt-4 text-grey md:text-xl text-[9px] ">Prize Pot</div>
                                


                                <div class="col-start-3 w-[250px] font-normal pl-4 pt-4">
                                    <div>
                                        <p class='text-binance md:text-lg text-[10px] font-medium'>Match first 1</p>
                                        <p class='text-grey md:text-xl text-xs font-normal'>{winningDetails.amountInINEX[0]} INEX</p>
                                        <p class='text-grey md:text-sm text-[9px]'>~${winningDetails.amountInUSD[0]}</p>
                                        <p class='text-grey md:text-sm text-[9px]'>{winningDetails.count[0]} Winning Tickets</p>
                                    </div>
                                </div>
                                <div class="md:col-start-4  w-[250px] col-start-4 col-span-2 flex justify-center md:justify-end font-normal md:pl-9  pt-4 pb-4 text-grey">
                                    <div>
                                        <p class='text-binance md:text-lg text-[10px] font-medium'>Match first 2</p>
                                        <p class='text-grey md:text-xl text-xs font-normal'>{winningDetails.amountInINEX[1]} INEX</p>
                                        <p class='text-grey md:text-sm text-[9px]'>~${winningDetails.amountInUSD[1]}</p>
                                        <p class='text-grey md:text-sm text-[9px]'>{winningDetails.count[1]} Winning Tickets</p>
                                    </div>
                                </div>
                                <div class="col-start-1 col-span-5 pl-4 -mt-16 text-grey md:text-3xl text-lg md:font-bold font-medium ">~${prizePot}</div>

                                <div class=" col-start-3 w-[250px] font-normal pl-4 pt-4">
                                    <div>
                                        <p class='text-binance md:text-lg text-[10px] font-medium'>Match First 3</p>
                                        <p class='text-grey md:text-xl text-xs font-normal'>{winningDetails.amountInINEX[2]} INEX</p>
                                        <p class='text-grey md:text-sm text-[9px]'>~${winningDetails.amountInUSD[2]}</p>
                                        <p class='text-grey md:text-sm text-[9px]'>{winningDetails.count[2]} Winning Tickets</p>
                                    </div>
                                </div>

                                <div class="md:col-start-4 w-[250px] col-start-4 col-span-2 font-normal flex md:justify-end justify-center md:pl-9 pt-4  text-grey">
                                    <div>
                                        <p class='text-binance md:text-lg text-[10px] font-medium'>Match First 4</p>
                                        <p class='text-grey md:text-xl text-xs font-normal'>{winningDetails.amountInINEX[3]} INEX</p>
                                        <p class='text-grey md:text-sm text-[9px]'>~${winningDetails.amountInUSD[3]}</p>
                                        <p class='text-grey md:text-sm text-[9px]'>{winningDetails.count[3]} Winning Tickets</p>
                                    </div>
                                </div>
                                <div class="col-start-1  col-span-2 font-normal w-[350px] pt-4  pl-4 text-grey md:text-sm text-[10px] ">Total tickets this round: <br /> {numOfPlayer}</div>
                                <div class=" col-start-3 w-[250px] font-normal pl-4 pt-8">
                                    <div>
                                        <p class='text-binance md:text-lg text-[10px] font-medium'>Match First 5</p>
                                        <p class='text-grey md:text-xl text-xs font-normal'>{winningDetails.amountInINEX[4]} INEX</p>
                                        <p class='text-grey md:text-sm text-[9px]'>~${winningDetails.amountInUSD[4]}</p>
                                        <p class='text-grey md:text-sm text-[9px]'>{winningDetails.count[4]} Winning Tickets</p>
                                    </div>
                                </div>

                                <div class="md:col-start-4 w-[250px] col-start-4 col-span-2 font-normal flex justify-center md:justify-end md:pl-9 pb-4 pt-8 text-grey">
                                    <div>
                                        <p class='text-binance md:text-lg text-[10px] font-medium'>Match First 6</p>
                                        <p class='text-grey md:text-xl text-xs font-normal'>{winningDetails.amountInINEX[5]} INEX</p>
                                        <p class='text-grey md:text-sm text-[9px]'>~${winningDetails.amountInUSD[5]}</p>
                                        <p class='text-grey md:text-sm text-[9px]'>{winningDetails.count[5]} Winning Tickets</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div class='mb-12 md:w-[450px] w-[250px]'><img src={img2} alt='tickets'></img></div> */}

                </div>
            </div>
            <div class='text-hover text-center font-medium md:mb-8 mb-4 md:text-[45px] md:text-2xl md:mt-32'>How To Play</div>
            <div class='text-grey text-center md:mb-12 mb-4 md:font-normal md:text-[26px] text-[12px] md:w-[1180px] md:mx-auto mx-4'>If the digits on your tickets match the winning numbers in the correct order, you win a portion of the prize pool.</div>
            <div class='md:mb-36 mb-12 flex min-w-full'><img width='100%' src={img} alt='tickets'></img></div>
            <div class='flex justify-center'>
                <div>
                    <div class='md:w-[100%] w-[400px] px-9 md:mb-32 mx-auto mt-12 md:mt-0'><img src={wc} alt='tickets'></img></div>
                    {/* <div class='md:w-[850px] w-[425px] px-9 md:mb-32 mx-auto mt-12 md:mt-0'><img src={hands} alt='tickets'></img></div> */}
                    <div class='md:w-[100%] w-[400px] px-9 md:mb-36 mx-auto mt-12 md:mt-0'><img src={pf} alt='tickets'></img></div>
                    <div class='flex justify-center'>
                    <div class='md:mb-32 mt-32 md:mt-32 md:inline-flex md:ml-56 mx-12'>
                        <span className="mb-12"><img className="md:w-[591.66px] md:h-[303px]" src={guide} alt='tickets'></img></span>
                        <span className="text-hover md:w-[500px] flex md:items-center mt-4 md:mt-0 text-center md:text-start md:justify-start justify-center font-medium md:ml-9 h-[303px] md:text-[40px] text-xl"><div>Still Got Questions?<br /><div class='md:text-[30px] text-[16px] md:ml-0 md:mt-7 mt-2 text-grey font-normal'>Check our in-depth </div><div class='text-hover md:mt-3 md:ml-0 md:text-[30px] text-[16px] font-normal'><span class='text-grey md:mt-3 md:text-[30px] text-[16px] font-normal'> guide on </span><a href="/how-to-play" class='cursor-pointer text-hover hover:text-[#24004E]'>how to play</a></div><div class='font-normal md:mt-3 text-grey md:ml-0 md:text-[30px] text-[16px]'>the indexx lottery!</div></div></span>

                    </div>
                    </div>
                </div>
            </div>
            <ConnectWallet

                isVisible={isModalOpen}
                onClose={() => setIsModalOpen(false)}
            />
        </>
    );
}
export default Section3;