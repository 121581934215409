//import logo from '../assets/white.png';
//import poly from '../assets/Polygon.png';
import background from '../assets/bg33.png';
//import user from '../assets/icons.png';
import './Header.css';
//import { CaretDownOutlined } from "@ant-design/icons";
import headerstar from "../assets/CEX.png";
import headerdex from "../assets/DEX.png";
import earn from "../assets/dex4t2e.png";
import bank from "../assets/iGrow.png";
import whitetoken from "../assets/Indexx Token.png";
import fortunedaily from "../assets/fortune.png";
import hedge from "../assets/hedge.png";
//import centralized from "../assets/CEX.png";
import about from "../assets/about.png";
import affiliate from "../assets/affiliate.png";
import blog from "../assets/blog.png";
import vlog from "../assets/cam2.png";
import career from "../assets/career.png";
import howitworks from "../assets/howitworks.png";
import hybrid from "../assets/hybrid.png";
import ham from '../assets/ham.svg';
//import { UserOutlined, } from '@ant-design/icons';

import { Typography } from "antd";
import { Link } from 'react-router-dom';
import { useState } from 'react';
import DisplayMessage from './DisplayMessage';

const { Text } = Typography;

const Header = ({ web3Handler, account, indexxFortuneLottery }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [title, setTitle] = useState("Oops!")
    const [msg, setMsg] = useState("");
    const handleClick = () => {
        //displayMessage("Oops!", "Kindly connect wallet to view profile", 0);
        setMsg("Kindly connect wallet to view profile");
        setTitle("Oops!");
        setIsModalOpen(true);

    }
    return (
        <header class="sticky top-0 text-white bg-blackbg " style={{ backgroundImage: `url(${background})`, backgroundRepeat: "no-repeat", backgroundSize: 'cover' }}>

            <div class="container mx-auto justify-center flex flex-wrap p-3 flex-row items-center">
                {/* <a href='https://indexx.ai/' class="flex title-font font-normal items-center text-white mb-4 md:mb-0"><img alt='img' src={logo} class='w-20 h-8' /></a> */}
                <a
                    href='https://indexx.ai/'
                    class="flex title-font font-normal items-center text-white mb-4 md:mb-0 logo__icon logo__icon2"
                >

                </a>
                <Link
                    to={{
                        pathname: "/*",

                    }}
                >
                    <span class="ml-3 text-xl text-white font-normal hover:text-opacity-80">Fortune Daily</span>
                </Link>


                <nav class="mobileheader ml-[30%] flex flex-wrap text-white justify-end mt-5 ">
                    <ul class="w-full">
                        <li class="dropdown relative inline pr-1 text-white cursor-pointer font-medium text-base">
                            <img alt='hambuger' src={ham} class='w-7 inline-flex justify-end mb-4' />
                            <div class="dropdown-menu absolute -right-[40px] z-10 top-0 hidden h-auto flex pt-4 mt-3">
                                <ul class="block w-[130px] bg-[#000000] bg-opacity-80 px-5 py-4 ">
                                    <li class="rounded-sm relative py-1">
                                        <button
                                            class="w-full text-left"
                                        >
                                            <span class="pr-1 font-normal text-sm focus:font-medium">Products</span>

                                        </button>
                                        <ul
                                            class="bg-[#000000] bg-opacity-80 py-4 text-sm absolute top-0 -right-[145px] w-64 transition duration-150 ease-in-out origin-top-right"
                                        >
                                            <li class="px-3 py-1 hover:bg-gray-100"><a href='https://cex.indexx.ai/indexx-exchange/buy-sell' class='text-white'>

                                                <Text style={{ paddingLeft: 4, color: 'white' }}>Centralized</Text><br />
                                                <Text style={{ fontSize: 10, color: 'white', marginLeft: 26 }}> Convert, Buy & Sell</Text></a></li>

                                            <li class="px-3 py-1 hover:bg-gray-100"><a href='/*' class='text-white'>

                                                <Text style={{ paddingLeft: 4, color: 'white' }}>Decentralized</Text><br />
                                                <Text style={{ fontSize: 10, color: 'white', marginLeft: 26 }}> Swap, Buy & Sell</Text>
                                            </a></li>
                                            <li class="px-3 py-1 hover:bg-gray-100"><a href='https://tokens.indexx.ai/' class='text-white'>

                                                <Text style={{ paddingLeft: 4, color: 'white' }}>indexx Tokens</Text><br />
                                                <Text style={{ fontSize: 10, color: 'white', marginLeft: 26 }}> Know the characteristics of each token</Text>
                                            </a></li>



                                        </ul>
                                    </li>

                                    <li class="rounded-sm relative py-1">
                                        <button
                                            class="w-full text-left"
                                        >
                                            <span class="pr-1 font-normal text-sm focus:font-medium">Earn</span>

                                        </button>
                                        <ul
                                            class="bg-[#000000] bg-opacity-80 text-sm absolute top-0 -right-[145px] w-64 py-4 transition duration-150 ease-in-out origin-top-right"
                                        >
                                            <li class="px-3 py-1 hover:bg-gray-100"><a href='/TradeToEarn' class='text-white'>

                                                <Text style={{ paddingLeft: 4, color: 'white' }}>Trade to Earn</Text><br />
                                                <Text style={{ fontSize: 10, color: 'white', marginLeft: 26 }}>Earn more while trading</Text>
                                            </a></li>

                                            <li class="px-3 py-1 hover:bg-gray-100">

                                                <Text style={{ paddingLeft: 4, color: 'white' }}>Indexx Grow</Text><br />
                                                <Text style={{ fontSize: 10, color: 'white', marginLeft: 26 }}>Deposit and invest to earn</Text>

                                            </li>
                                        </ul>
                                    </li>

                                    <li class="rounded-sm relative py-1">
                                        <button
                                            class="w-full text-left"
                                        >
                                            <span class="pr-1 font-normal text-sm focus:font-medium">Company</span>

                                        </button>
                                        <ul
                                            class="bg-[#000000] bg-opacity-80 text-sm absolute top-0 -right-[145px] w-64 py-4 transition duration-150 ease-in-out origin-top-right "
                                        >
                                            <li class="px-3 py-1 hover:bg-gray-100"><a href='https://cex.indexx.ai/indexx-exchange/how-it-works' class='text-white' >

                                                <Text style={{ paddingLeft: 4, color: 'white' }}>How it Works</Text><br />
                                                <Text style={{ fontSize: 10, color: 'white', marginLeft: 26 }}>Know how the different features  work</Text>
                                            </a></li>
                                            <li class="px-3 py-1 hover:bg-gray-100"><a href='https://cex.indexx.ai/indexx-exchange/about' class='text-white' >

                                                <Text style={{ paddingLeft: 4, color: 'white' }}>About</Text><br />
                                                <Text style={{ fontSize: 10, color: 'white', marginLeft: 26 }}>Background of the company</Text>
                                            </a></li>

                                            <li class="px-3 py-1 hover:bg-gray-100 "><a href='https://indexx.ai/' class='text-white'>

                                                <Text style={{ paddingLeft: 4, color: 'white' }}>Hybrid Exchange</Text><br />
                                                <Text style={{ fontSize: 10, color: 'white', marginLeft: 26 }}>Centralize and Decentralize Exchange</Text></a></li>
                                            <li class="px-3 py-1 hover:bg-gray-100"><a href='https://register.affiliate.indexx.ai/' class='text-white'>

                                                <Text style={{ paddingLeft: 4, color: 'white' }}>Affiliate Program</Text><br />
                                                <Text style={{ fontSize: 10, color: 'white', marginLeft: 26 }}>Earn while sharing</Text>
                                            </a></li>
                                            <li class="px-3 py-1 hover:bg-gray-100"><a href='https://cex.indexx.ai/indexx-exchange/blog' class='text-white'>

                                                <Text style={{ paddingLeft: 4, color: 'white' }}>Blog</Text><br />
                                                <Text style={{ fontSize: 10, color: 'white', marginLeft: 26 }}>Updates and trends in the crypto world</Text>
                                            </a></li>

                                            <li class="px-3 py-1 hover:bg-gray-100"><a href='https://cex.indexx.ai/indexx-exchange/careers' class='text-white'>

                                                <Text style={{ paddingLeft: 4, color: 'white' }}>Careers</Text><br />
                                                <Text style={{ fontSize: 10, color: 'white', marginLeft: 26 }}>Find opportunity in indexx.ai</Text>
                                            </a></li>


                                        </ul>
                                    </li>

                                    <li class="rounded-sm relative py-1">
                                        <button
                                            class="w-full text-left"
                                        >
                                            <span class="pr-1 font-normal text-sm focus:font-medium">Fortune</span>

                                        </button>
                                        <ul
                                            class="bg-[#000000] bg-opacity-80 text-sm absolute top-0 -right-[145px] w-64 transition duration-150 ease-in-out origin-top-right py-4"
                                        >
                                            <li class="px-3 py-1 hover:bg-gray-100">

                                                <Text style={{ paddingLeft: 4, color: 'white' }}>Fortune Daily</Text><br />
                                                <Text style={{ fontSize: 10, color: 'white', marginLeft: 26 }}>Win daily in lottery</Text>
                                            </li>
                                            <li class="px-3 py-1 hover:bg-gray-100">

                                                <Text style={{ paddingLeft: 4, color: 'white' }}>Fortune Rush</Text><br />
                                                <Text style={{ fontSize: 10, color: 'white', marginLeft: 26 }}>Win hourly in lottery</Text>
                                            </li>

                                            <li class="px-3 py-1 hover:bg-gray-100">

                                                <Text style={{ paddingLeft: 4, color: 'white' }}>Hedge Edge</Text><br />
                                                <Text style={{ fontSize: 10, color: 'white', marginLeft: 26 }}>Predict to win</Text>

                                            </li>

                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>

                </nav>

                <nav class="ml-[100px] flex flex-wrap items-center text-white justify-center my-3 chart">
                    <span class="mr-9 text-white inline-flex">
                        <ul class="w-full">
                            <li class="dropdown relative inline pr-1 text-white hover:text-opacity-70 cursor-pointer font-normal text-base">
                                
                                    <span>Products</span>
                               
                                <div class="dropdown-menu absolute top-0 hidden h-auto flex pt-4 mt-3">
                                    <ul class="block w-[300px] bg-[#171717] bg-opacity-90 px-5 py-4 ">
                                        <li class="py-1">
                                            <a
                                                href={`https://cex.indexx.ai/indexx-exchange/buy-sell/get-started`}
                                                class="block text-white font-normal text-sm cursor-pointer"
                                            >
                                                <img
                                                    alt="img"
                                                    class="inline-flex"
                                                    preview={false}
                                                    src={headerstar}

                                                    style={{
                                                        width: 15,
                                                        height: 15,
                                                        marginBottom: -3,
                                                        marginRight: 7,
                                                    }}
                                                />
                                                <span class="text-white hover:text-[#1DB1F5]">
                                                    <span style={{ paddingLeft: 4 }}>Centralized</span>
                                                    <br />
                                                    <span style={{ fontSize: 10, marginLeft: 26 }}>
                                                        {" "}
                                                        Convert, Buy & Sell
                                                    </span>
                                                </span>
                                            </a>
                                        </li>



                                        <li class="py-1">
                                            <a
                                                href="https://dex.indexx.ai/*"
                                                class="block text-white font-normal text-sm  cursor-pointer"
                                            >
                                                <img
                                                    alt="img"
                                                    class="inline-flex"
                                                    preview={false}
                                                    src={headerdex}
                                                    style={{
                                                        width: 15,
                                                        height: 15,
                                                        marginBottom: -3,
                                                        marginRight: 7,
                                                    }}
                                                />
                                                <span class="text-white hover:text-[#1DB1F5]">
                                                    <span style={{ paddingLeft: 4 }}>Decentralized</span>
                                                    <br />
                                                    <span style={{ fontSize: 10, marginLeft: 26 }}>
                                                        {" "}
                                                        Swap, Buy & Sell
                                                    </span>
                                                </span>
                                            </a>
                                        </li>
                                        <li class="py-1">
                                            <a
                                                href="https://indexx.ai/indexx-exchange/token-details"
                                                class="block text-white font-normal text-sm cursor-pointer"
                                            >
                                                <img
                                                    alt="img"
                                                    class="inline-flex"
                                                    preview={false}
                                                    src={whitetoken}
                                                    style={{
                                                        width: 15,
                                                        height: 15,
                                                        marginBottom: -3,
                                                        marginRight: 7,
                                                    }}
                                                />
                                                <span class="text-white hover:text-[#1DB1F5]">
                                                    <span style={{ paddingLeft: 4 }}>indexx Tokens</span>
                                                    <br />
                                                    <span style={{ fontSize: 10, marginLeft: 26 }}>
                                                        {" "}
                                                        Know the characteristics of each token
                                                    </span>
                                                </span>
                                            </a>
                                        </li>
                                        <li class="py-1">
                                            <a
                                                href="https://dex.indexx.ai/import-indexx-tokens"
                                                class="block text-white font-normal text-sm cursor-pointer"
                                            >
                                                <img
                                                    alt="img"
                                                    class="inline-flex"
                                                    preview={false}
                                                    src={whitetoken}
                                                    style={{
                                                        width: 15,
                                                        height: 15,
                                                        marginBottom: -3,
                                                        marginRight: 7,
                                                    }}
                                                />
                                                <span class="text-white hover:text-[#1DB1F5]">
                                                    <span style={{ paddingLeft: 4 }}>
                                                        Import indexx Tokens
                                                    </span>
                                                    <br />
                                                    <span style={{ fontSize: 10, marginLeft: 26 }}>
                                                        {" "}
                                                        Bring your tokens to our exchange
                                                    </span>
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </span>

                    <span class="mr-9 text-white inline-flex">
                        <ul class="w-full">
                            <li class="relative inline pr-1 cursor-pointer font-normal text-base">

                                <a href="https://indexx.ai/indexx-exchange/markets" class="text-white hover:text-opacity-70">Markets</a>
                            </li>
                        </ul>
                    </span>

                    <span class="mr-9 text-white inline-flex">
                        <ul class="w-full">
                            <li class="dropdown relative inline pr-1 text-white hover:text-[#1DB1F5] cursor-pointer font-normal text-base">
                                <span class="">Earn</span>
                                <div class="dropdown-menu absolute top-0 hidden h-auto flex pt-4 mt-3">
                                    <ul class="block w-[300px] bg-[#171717] bg-opacity-90   px-5 py-4 ">
                                        <li class="py-1">
                                            <a
                                                href="https://dex.indexx.ai/TradeToEarn"
                                                class="block text-white font-normal text-sm cursor-pointer"
                                            >
                                                <img
                                                    alt="img"
                                                    class="inline-flex"
                                                    preview={false}
                                                    src={earn}
                                                    style={{
                                                        width: 15,
                                                        height: 15,
                                                        marginBottom: -3,
                                                        marginRight: 7,
                                                    }}
                                                />
                                                <span class="text-white hover:text-[#1DB1F5]">
                                                    <span style={{ paddingLeft: 4 }}>Trade to Earn</span>
                                                    <br />
                                                    <span style={{ fontSize: 10, marginLeft: 26 }}>
                                                        Earn more while trading
                                                    </span>
                                                </span>
                                            </a>
                                        </li>
                                        <li class="py-1">
                                            <span

                                                class="block text-white font-normal text-sm cursor-pointer"
                                            >
                                                <img
                                                    alt="img"
                                                    class="inline-flex"
                                                    preview={false}
                                                    src={bank}
                                                    style={{
                                                        width: 15,
                                                        height: 15,
                                                        marginBottom: -3,
                                                        marginRight: 7,
                                                    }}
                                                />
                                                <a
                                                    href="https://indexx.ai/indexx-exchange/coming-soon?page=Indexx%20Bank"
                                                    class="text-white hover:text-[#1DB1F5]"
                                                >
                                                    <span style={{ paddingLeft: 4 }}>Indexx Bank</span>
                                                    <br />
                                                    <span style={{ fontSize: 10, marginLeft: 26 }}>
                                                        Deposit and invest to earn
                                                    </span>
                                                </a>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </span>
                    <span class="mr-9 text-white inline-flex">
                        <ul class="w-full">
                            <li class="dropdown relative inline pr-1 text-white hover:text-opacity-70 cursor-pointer font-normal  text-base">
                                <span class="">Company</span>
                                <div class="dropdown-menu absolute top-0 hidden h-auto flex pt-4 mt-3">
                                    <ul class="block w-[300px] bg-[#171717] bg-opacity-90   px-5 py-4">
                                        <li class="py-1">
                                            <a
                                                href={`https://indexx.ai/indexx-exchange/how-it-works`}
                                                class="block text-white font-normal text-sm cursor-pointer"
                                            >
                                                <img
                                                    alt="img"
                                                    class="inline-flex"
                                                    preview={false}
                                                    src={howitworks}
                                                    style={{
                                                        width: 15,
                                                        height: 15,
                                                        marginBottom: -3,
                                                        marginRight: 7,
                                                    }}
                                                />
                                                <span class="text-white hover:text-[#1DB1F5]">
                                                    <span style={{ paddingLeft: 4 }}>How it Works</span>
                                                    <br />
                                                    <span style={{ fontSize: 10, marginLeft: 26 }}>
                                                        Know how the different features work
                                                    </span>
                                                </span>
                                            </a>
                                        </li>
                                        <li class="py-1">
                                            <a
                                                href={`https://indexx.ai/indexx-exchange/about`}
                                                class="block text-white font-normal text-sm cursor-pointer"
                                            >
                                                <img
                                                    alt="img"
                                                    class="inline-flex"
                                                    preview={false}
                                                    src={about}
                                                    style={{
                                                        width: 15,
                                                        height: 15,
                                                        marginBottom: -3,
                                                        marginRight: 7,
                                                    }}
                                                />
                                                <span class="text-white hover:text-[#1DB1F5]">
                                                    <span style={{ paddingLeft: 4 }}>About</span>
                                                    <br />
                                                    <span style={{ fontSize: 10, marginLeft: 26 }}>
                                                        Background of the company
                                                    </span>
                                                </span>
                                            </a>
                                        </li>
                                        <li class="py-1">
                                            <a
                                                href='https://indexx.ai/'
                                                class="block text-white font-normal text-sm cursor-pointer"
                                            >
                                                <img
                                                    alt="img"
                                                    class="inline-flex"
                                                    preview={false}
                                                    src={hybrid}
                                                    style={{
                                                        width: 15,
                                                        height: 15,
                                                        marginBottom: -3,
                                                        marginRight: 7,
                                                    }}
                                                />
                                                <span class="text-white hover:text-[#1DB1F5]">
                                                    <span style={{ paddingLeft: 4 }}>
                                                        Hybrid Exchange
                                                    </span>
                                                    <br />
                                                    <span style={{ fontSize: 10, marginLeft: 26 }}>
                                                        Centralize and Decentralize Exchange
                                                    </span>
                                                </span>
                                            </a>
                                        </li>
                                        <li class="py-1 ">
                                            <a
                                                href="https://register.affiliate.indexx.ai/"
                                                class="block text-white font-normal text-sm cursor-pointer"
                                            >
                                                <img
                                                    alt="img"
                                                    class="inline-flex"
                                                    preview={false}
                                                    src={affiliate}
                                                    style={{
                                                        width: 15,
                                                        height: 15,
                                                        marginBottom: -3,
                                                        marginRight: 7,
                                                    }}
                                                />
                                                <span class="text-white hover:text-[#1DB1F5]">
                                                    <span style={{ paddingLeft: 4 }}>
                                                        Affiliate Program
                                                    </span>
                                                    <br />
                                                    <span style={{ fontSize: 10, marginLeft: 26 }}>
                                                        Earn while sharing
                                                    </span>
                                                </span>
                                            </a>
                                        </li>
                                        <li class="py-1">
                                            <a
                                                href={`https://indexx.ai/indexx-exchange/blog`}
                                                class="block text-white font-normal text-sm cursor-pointer"
                                            >
                                                <img
                                                    alt="img"
                                                    class="inline-flex"
                                                    preview={false}
                                                    src={blog}
                                                    style={{
                                                        width: 15,
                                                        height: 15,
                                                        marginBottom: -3,
                                                        marginRight: 7,
                                                    }}
                                                />
                                                <span class="text-white hover:text-[#1DB1F5]">
                                                    <span style={{ paddingLeft: 4 }}>Blog</span>
                                                    <br />
                                                    <span style={{ fontSize: 10, marginLeft: 26 }}>
                                                        Updates and trends in the crypto world
                                                    </span>
                                                </span>
                                            </a>
                                        </li>

                                        <li class="py-1">
                                            <a
                                                href={`https://indexx.ai/indexx-exchange/vlog`}
                                                class="block text-white font-normal text-sm cursor-pointer"
                                            >
                                                <img
                                                    alt="img"
                                                    class="inline-flex"
                                                    preview={false}
                                                    src={vlog}
                                                    style={{
                                                        width: 15,
                                                        height: 15,
                                                        marginBottom: -3,
                                                        marginRight: 7,
                                                    }}
                                                />
                                                <span class="text-white hover:text-[#1DB1F5]">
                                                    <span style={{ paddingLeft: 4 }}>Vlog</span>
                                                    <br />
                                                    <span style={{ fontSize: 10, marginLeft: 26 }}>
                                                        Updates and trends in the crypto world
                                                    </span>
                                                </span>
                                            </a>
                                        </li>

                                        <li class="py-1">
                                            <a
                                                href={`https://indexx.ai/indexx-exchange/careers`}
                                                class="block text-white font-normal text-sm cursor-pointer"
                                            >
                                                <img
                                                    alt="img"
                                                    class="inline-flex"
                                                    preview={false}
                                                    src={career}
                                                    style={{
                                                        width: 15,
                                                        height: 15,
                                                        marginBottom: -3,
                                                        marginRight: 7,
                                                    }}
                                                />
                                                <span class="text-white hover:text-[#1DB1F5]">
                                                    <span style={{ paddingLeft: 4 }}>Careers</span>
                                                    <br />
                                                    <span style={{ fontSize: 10, marginLeft: 26 }}>
                                                        Find opportunity in indexx.ai
                                                    </span>
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </span>

                    <span class="mr-[60px] text-white inline-flex">
                        <ul class="w-full">
                            <li class="dropdown relative inline pr-1 text-white hover:text-opacity-70 cursor-pointer font-normal text-base">
                               
                                    <span class=" py-1">Fortune</span>

                                <div class="dropdown-menu absolute top-0 hidden h-auto flex pt-4 mt-3">
                                    <ul class="block w-[300px] bg-[#171717] bg-opacity-90   px-5 py-4">
                                        <li class="py-1">
                                            <a
                                                href="https://fortune.rush.indexx.ai/"
                                                class="block text-white font-normal text-sm cursor-pointer"
                                            >
                                                <img
                                                    alt="img"
                                                    class="inline-flex"
                                                    preview={false}
                                                    src={fortunedaily}
                                                    style={{
                                                        width: 15,
                                                        height: 15,
                                                        marginBottom: -3,
                                                        marginRight: 7,
                                                    }}
                                                />
                                                <span class="text-white hover:text-[#1DB1F5]">
                                                    <span style={{ paddingLeft: 4 }}>Fortune Rush</span>
                                                    <br />
                                                    <span style={{ fontSize: 10, marginLeft: 26 }}>
                                                        Win hourly in lottery
                                                    </span>
                                                </span>
                                            </a>
                                        </li>
                                        <li class="py-1">
                                            <a
                                                href="https://dex.indexx.ai/Fortune/HedgeEdge"
                                                class="block text-white font-normal text-sm cursor-pointer"
                                            >
                                                <img
                                                    alt="img"
                                                    class="inline-flex"
                                                    preview={false}
                                                    src={hedge}
                                                    style={{
                                                        width: 15,
                                                        height: 15,
                                                        marginBottom: -3,
                                                        marginRight: 7,
                                                    }}
                                                />
                                                <span class="text-white hover:text-[#1DB1F5]">
                                                    <span style={{ paddingLeft: 4 }}>Hedge Edge</span>
                                                    <br />
                                                    <span style={{ fontSize: 10, marginLeft: 26 }}>
                                                        Predict to win
                                                    </span>
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                        {/* <img alt='img' src={poly} class='w-1 h-[4px] mt-[13px]' /> */}
                    </span>
                    <span class='ml-14'><Link to='/how-to-play' className='hover:text-opacity-80 text-white text-sm'>How To Play</Link></span>

                    <span>
                        {
                            account && indexxFortuneLottery.signer ? (
                                <span>
                                    <a
                                        href={`https://bscscan.com/address/${account}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        class="flex justify-center ml-9 border rounded-full text-xs py-1 w-[100px] hover:border-[#9F9E9E] hover:text-[#9F9E9E] cursor-pointer">
                                        {account.slice(0, 5) + '...' + account.slice(38, 42)}
                                    </a>
                                </span>
                            ) : (

                                <span onClick={web3Handler} class='flex justify-center ml-9 border rounded-full text-xs py-1 w-[100px] hover:border-[#9F9E9E] hover:text-[#9F9E9E] cursor-pointer'>Connect Wallet</span>
                            )}
                    </span>

                    {
                        account ? (
                            <span>
                                <Link
                                    to={{
                                        pathname: "/User-Profile",
                                    }}
                                >
                                    <button
                                        href='/User-Profile'
                                        class="ml-9 flex title-font font-normal items-center text-white mb-4 md:mb-0 user__icon user__icon2"
                                    >
                                    </button>
                                </Link>
                            </span>) : (
                            <span>
                                <Link
                                    to={{
                                        pathname: "/User-Profile",
                                    }}
                                >
                                    <button onClick={handleClick}
                                        href='/User-Profile'
                                        class="ml-9 flex title-font font-normal items-center text-white mb-4 md:mb-0 user__icon user__icon2"
                                    >
                                    </button>
                                </Link>
                            </span>
                        )
                    }


                    {
                        // <span class='flex justify-end ml-9'>
                        //    <a href='/User-Profile'><img alt='img' width='24' src={user} /></a>
                        // </span> 
                    }

                </nav>

            </div>

            <DisplayMessage title={title} isVisible={isModalOpen} message={msg} onClose={() => setIsModalOpen(false)} />


        </header >
    );
}

export default Header;