import img from "../assets/cross.png";
import React, { useState } from 'react';
import purple from '../assets/purple-1.png';
//import swal from "sweetalert";
import { Link, useNavigate } from "react-router-dom";
import loadingg from '../assets/Indexx-Fortune.gif'
// import Number from "../components/Modals/Number";
//import Range from "../components/Modals/Range";
// import Meta from "../components/Modals/Meta";
// import ConnectWallet from "../components/Modals/ConnectWallet";
//import extractErrorCode from "../components/ErrorMessage";
//import swal from "sweetalert";
// import '././ErrorMessage.css';
import '../components/ErrorMessage.css'
//import cross from '../assets/cross-resized.png'
import DisplayMessage from "../components/DisplayMessage";

const Buy = ({ web3Handler, indexxFortuneLottery, account,switchNetwork }) => {
    // let account = localStorage.getItem('account');
    const [isModalOpen, setIsModalOpen] = useState(false);
    // const [isModalOpen2, setIsModalOpen2] = useState(false);
    // const [isModalOpen3, setIsModalOpen3] = useState(false);
    // const [isModalOpen4, setIsModalOpen4] = useState(false);
    //const [maximum, setMax] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [title, setTitle] = useState("Oops!")
    const [msg, setMsg] = useState("");
    const [data, setData] = useState({
        lotteryId: '',
        ticketNumber: ''
    });

    function extractErrorCode(str) {

        console.log("in extract", str);

        let ans = "An error occured";
        let firstOccurence, secondOccurence;
        let strmsg = str.toString();
        if (strmsg.includes("is not a function")) {
            ans = "Kindly connect your wallet again";
        }
        else if (strmsg.includes('Nonce too high')) {
            console.log("Nonce too high");
            ans = '\t\tNonce is too High\n Reset your acc using: \n settings-> Advanced-> Reset your account';
        }
        else {
            str = JSON.stringify(str);
            console.log(str)
            if (str.includes("NetworkError when attempting to fetch resource")) {
                ans = "Network Error: Binance Smart Chain Network unavailable.\n Reload and try again later";

            } else if (str.includes("MetaMask is having trouble connecting to the network")) {
                ans = "MetaMask is having trouble connecting to the network.\n Try again later";

            }
            else if (str.includes("___")) {
                const delimiter = '___'; //Replace it with the delimiter you used in the Solidity Contract.
                firstOccurence = str.indexOf(delimiter);
                secondOccurence = str.indexOf(delimiter, firstOccurence + 1);
                if (secondOccurence === -1){
                    ans="An error occured. Kindly retry";
                }
                else {
                    ans = str.substring(firstOccurence + delimiter.length, secondOccurence);

                }
            } else {
                console.log("JSON");
                var j = JSON.parse(str);
                if (j.reason) {
                    var reason = j["reason"].substring(27);
                    console.log(reason);
                    if(reason!=="")
                    ans = reason;
                    

                } else {
                    ans = 'An error occured. Kindly retry.'
                }
            }
        }
        setIsModalOpen(true);
        setMsg(ans);
        setTitle("Oops!");
        console.log("Erorr: in edit new ", ans);
    }
    const onChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };
    
    const handleEdit = async (e) => {
        if (indexxFortuneLottery.signer === null) {
            setTitle("Oops!");
            setMsg("Please connect your metamask account before buying ticket.");
            setIsModalOpen(true);
            setLoading(false);
            return;
        }
        setLoading(true);
        try {
            let x = await switchNetwork();
            while (x === 0) {
                setMsg("We are currently available on BSC only. Kindly switch to Binance Smart Chain (BSC).");
                setTitle("Oops!");
                setIsModalOpen(true);
                x = await switchNetwork();
                // return;
            }
        } catch (err) {
            
        }


        console.log("Acc in Buy.js: ", account, data.ticketNumber, indexxFortuneLottery);
        data.ticketNumber = data.ticketNumber.trim();
        if (account !== null) {
            if (data.ticketNumber.length === 0) {
                setIsModalOpen(true);
                setMsg("Kindly enter number of tickets you want to purchase.");
                setLoading(false);
                return;
            }
            const regexp = /^[0-9\b]+$/;
            if (!regexp.test(data.ticketNumber)) {
                // swal("", "Enter only numeric values", {
                //     buttons: {
                //         confirm: {
                //             className: 'bg-binance hover:[#3C0180] px-14 py-1 rounded-sm',
                //         }

                //     }
                // });

                setTitle("Oops!");
                setMsg("Enter only numeric values");
                setIsModalOpen(true);
                console.log("Messsage: ", msg);


                setLoading(false);
                return;
            }
            const ticketNumber = parseInt(data.ticketNumber);
            localStorage.setItem("numOfTickets", ticketNumber);
            let numbers = [];
            for (let i = 0; i < ticketNumber; i++) {
                let randomNum = Math.floor(Math.random() * Math.pow(10, 6)).toString().padStart(6, '0');
                numbers.push(
                    {
                        id: i,
                        number: randomNum
                    });
            }
            localStorage.setItem("randomNumbers", JSON.stringify(numbers))
            console.log("Buyyy.js");
            let max;
            try {
                max = await indexxFortuneLottery.maxNumberTicketsPerBuyOrClaim();
                console.log("max", max);
                localStorage.setItem('maxTicket', max);
                if (ticketNumber > 0 && ticketNumber <= max) {
                    setLoading(false);
                    navigate('/View-Edit-Numbers');
                    // displayMessage("Hurray", "Will be navigated", 1);

                } else {
                    // setIsModalOpen(true);
                    // setMax(max);
                    setTitle("Oops!");
                    setMsg("Please enter the number between 1 and " + max);
                    setIsModalOpen(true);
                    setLoading(false);
                    //displayMessage("Oops!", "Please enter the number between 1 and " + max, 0);
                }
            } catch (error) {
                extractErrorCode(error);
                setLoading(false);
            }
        } else {
            // console.log(account);
            // setIsModalOpen3(true);
            setTitle("Oops!");
            setMsg("Please connect your metamask account before buying ticket.");
            setIsModalOpen(true);
            setLoading(false);
            // connect wallet
        }
    }
   
    // useEffect(() => {

    // console.log(indexxFortuneLottery);
    // if (!indexxFortuneLottery.interface) {
    //     swal('Connect Wallet again', '', 'warning');
    // navigate('/login');
    // }
    // }, []);
    // console.log(data);
    return (
        <div class="py-1 flex justify-center items-center pt-12 content-center">
            <div>
                <div class='mb-7'><img className="md:flex md:justify-center ml-32 md:ml-0 w-24 md:ml-[300px]" src={purple} alt='purpple' ></img></div>
                <div class="grid grid-cols-3 gap-x-12  border border-grey border-opacity-25 rounded-sm md:w-[670px] w-[340px]">
                    <div class="col-start-1 col-span-2 font-medium px-8 pb-4 pt-7 text-grey md:text-sm text-xs">Buy Tickets</div>
                    <div class="col-start-3 col-span-full pb-4 pt-7 px-8 text-grey text-xs flex justify-end">
                        <Link to="/">
                            <img src={img} alt='tickets' class='inline' />
                        </Link>
                        </div>

                    <div class="col-start-1 col-span-1 font-normal pb-4 pl-8 text-grey md:text-sm text-xs ">Buy:</div>
                    <div class="col-start-2 col-span-full font-bold pb-4 pr-8 text-grey md:text-sm text-xs flex justify-end">Tickets</div>
                    <div class="col-start-1 col-span-full flex justify-center p-4">

                        <input onChange={onChange} class="appearance-none border border-grey border-opacity-20 rounded-sm w-[601px] py-2 md:text-sm text-xs px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" name='ticketNumber' type="text" placeholder="Number of tickets" />
                    </div>
                    {
                        <div class="col-start-1 col-span-2 font-normal py-4 px-8 text-grey md:text-sm text-xs ">Lottery Round {localStorage.getItem('lotteryId')}</div>
                        // <div class="col-start-1 col-span-2 font-normal py-4 px-8 text-grey md:text-sm text-xs ">You pay :</div>
                    }
                    <div class="col-start-1 col-span-3 p-4 flex justify-center md:text-sm text-xs">
                        {
                            // <button onClick={web3Handler} class='bg-binance hover:bg-hover py-5 w-[601px] rounded-sm text-whitebg font-medium'>
                            // Buy Instantly
                            // </button>
                        }
                    </div>
                    <div class="col-start-1 col-span-3 px-4 pb-4 flex justify-center md:text-sm text-xs">
                        {
                            // <a href="">
                        }
                        <button onClick={handleEdit} disabled={loading} class='hover:text-[#B16CFF] bg-binance hover:bg-hover py-3 w-[601px] text-whitebg font-medium'>
                            View/ Edit Numbers {">"}
                        </button>
                    </div>
                    <div class="col-start-1 col-span-3 px-4 pb-4 flex justify-center md:text-sm text-xs">

                        {
                            loading ? (
                                <center>
                                    <img
                                        visible={true}
                                        height="80"
                                        width="80"
                                        src={loadingg}
                                        alt='img'
                                    />
                                </center>
                            ) : null}
                        {
                            //</a>
                        }
                    </div>


                    <div class="col-start-1 col-span-3 px-8 pt-4 pb-8 flex justify-center md:text-sm text-xs text-grey">
                        Prices are set before each round starts. You can see your total payable amount on the next page.
                    </div>
                    {
                        // <Number
                        //     isVisible={isModalOpen}
                        //     onClose={() => setIsModalOpen(false)}
                        // />


                        //     <Meta
                        //     isVisible={isModalOpen3}
                        //     onClose={() => setIsModalOpen3(false)}
                        // />
                        // <ConnectWallet
                        //     isVisible={isModalOpen4}
                        //     onClose={() => setIsModalOpen4(false)}
                        // />
                    }
                </div>
                <DisplayMessage title={title} isVisible={isModalOpen} message={msg} onClose={() => setIsModalOpen(false)} />
            </div>
        </div>
    );
}

export default Buy;