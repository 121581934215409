import img2 from '../assets/Group 126.png';
import { useNavigate } from 'react-router-dom';
import Meta from './Modals/Meta';
import { useEffect, useState } from 'react';

const Box = ({ account, indexxFortuneLottery }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [prizePot, setPrizePot] = useState(101899);
    const navigate = useNavigate();
    const handleSubmit = async (e) => {

        if (account != null) {
            localStorage.setItem('account', account)
            navigate('/Buy');
        } else {
            setIsModalOpen(true);
        }
    }
    const calculatePrizePot = async () => {
        // const lotteryId = localStorage.getItem('lotteryId');
        let lotteryId = await indexxFortuneLottery.viewCurrentLotteryId();
        lotteryId = parseInt(lotteryId.toString(), 10);
        let amount = await indexxFortuneLottery.viewLottery(lotteryId);
        amount = parseInt(amount[11].toString(), 10);
        amount /= 1e18;
        console.log("Amount in INEX: ", amount, amount * 0.2);
        amount *=0.2 ;
        amount=amount.toFixed(2);
        console.log("Setting: ", amount);
        setPrizePot(amount);
        localStorage.setItem('prizePot', amount);
    }
    useEffect(() => {
        if (indexxFortuneLottery.signer)
            calculatePrizePot();


    })

    return (
        <div className="flex justify-center">
            <div>
                <div class="flex justify-center font-normal md:text-sm text-[#3C0180] text-[10px]"></div>


                <div className='flex justify-center text-hover '>
                    <div class="font-bold flex  p-4 md:text-5xl text-lg  ">Prize Pot</div>
                    <div class="col-start-3 col-span-3 p-4 md:text-5xl text-xl md:font-bold font-bold">~${prizePot}</div>

                </div>
                <div></div>
                <div class='mt-4 flex justify-center'><img src={img2} alt='tickets' class='md:w-[390px] w-40 '></img></div>
                <div class='mt-28 flex justify-center text-hover md:text-4xl text-xl text-center'>Decentralized lottery powered by Blockchain</div>
                <div class='my-9 flex justify-center '>
                    <button onClick={handleSubmit} class='bg-binance hover:text-[#B16CFF] font-medium text-white hover:bg-hover w-40 py-3 md:w-56' >
                        Buy Tickets
                    </button>

                </div>

            </div>
            <Meta
                isVisible={isModalOpen}
                onClose={() => setIsModalOpen(false)}
            />

        </div>

    );
}

export default Box;