import Box from '../components/Box';
import Section2 from '../components/Section2';
import Section3 from '../components/Section3';
import { useState, useEffect } from 'react';
// import img from '../assets/Group 164.png';
import home_page_logo from '../assets/home_page_logo.png'
import './Home.css';
import DisplayMessage from '../components/DisplayMessage';

// const targetTime = new Date("2023-1-23").getTime();
const Home = ({
  web3Handler,
  account,
  indexxFortuneLottery,
  switchNetwork,
}) => {
  // const [currentTime, setCurrentTime] = useState(Date.now());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [title, setTitle] = useState('Oops!');
  const [msg, setMsg] = useState('');
  const [time, setTime] = useState({
    timeBetween: '',
    minutes: '30',
    hours: '2',
    days: '4',
  });
  // const timeBetween = targetTime - currentTime;
  // const minutes = Math.floor((timeBetween / 1000 / 60) % 60);
  // const hours = Math.floor((timeBetween / (1000 * 60 * 60)) % 24);
  // const days = Math.floor(timeBetween / (1000 * 60 * 60 * 24));

  const calcTime = async () => {
    // console.log("incalcTime:");
    let currentTime, targetTime;
    let timeBetween, minutes, hours, days;

    try {
      currentTime = await indexxFortuneLottery.viewCurTime();
      const lotteryId = localStorage.getItem('lotteryId');
      targetTime = await indexxFortuneLottery.viewLottery(lotteryId);
      targetTime = parseInt(targetTime[2].toString(), 10);
      timeBetween = targetTime - currentTime;
      days = Math.floor(timeBetween / (3600 * 24));
      timeBetween = timeBetween % (24 * 3600);
      hours = Math.floor(timeBetween / (60 * 60));
      timeBetween %= 3600;
      minutes = Math.floor(timeBetween / 60);
    } catch (error) {
      console.log(error);
    }

    if (minutes < 0) {
      minutes = hours = days = 0;
    }
    setTime({
      timeBetween: timeBetween,
      minutes: minutes,
      hours: hours,
      days: days,
    });
  };
  const handleSwitch = async () => {
    try {
      let x = await switchNetwork();
      console.log('LL: ', x);
      if (x === 0) {
        setMsg(
          'We are currently available on BSC only. Kindly switch to Binance Smart Chain (BSC).'
        );
        console.log('pop', msg);
        setTitle('Oops!');
        console.log('pop', title);

        setIsModalOpen(true);
        console.log('pop', isModalOpen);

        x = await switchNetwork();
        // return;
      }
    } catch (err) {}
  };
  useEffect(() => {
    const interval = setInterval(() => {
      // setCurrentTime(Date.now());
      if (indexxFortuneLottery.signer) {
        calcTime();
      }
      handleSwitch();
    }, 10000);

    return () => clearInterval(interval);
  });

  return (
    <div class=''>
      <div>
        <div class='min-w-full min-h-full'>
          <img
            alt='img'
            className='bg3 h-full w-full'
            src={home_page_logo}
            style={{ height: '80%' }}
          ></img>

          {/* <img alt="img" className="bg3 h-full w-full" src={img} style={{ height: '80%' }}></img> */}
        </div>
        <div class='md:text-4xl text-xl  pb-6 flex justify-center font text-[#3C0180] tracking-[-0.01em]'>24 Hours Lottery Winning System</div>

        <div class='md:text-5xl text-xl pt-24 pb-6 flex justify-center font-bold text-[#3C0180]'>
          Get Your Tickets Now!
        </div>
        <div class='flex justify-center pt-4 text-grey text-white'>
          <div class=' grid justify-items-center w-[550px]'>
            <div className='background mobilebackground'>
              <div class='md:ml-44 text-white md:mt-5 mt-1 md:text-sm text-[9px] ml-[40px]'>
                Next Draw Remaining Time
              </div>
              <div class='inline-flex md:ml-44 ml-[44px]'>
                <span class='md:text-[44px] text-[24px] text-white font-bold md:tracking-[10px]'>
                  <div>{time.days} </div>
                  <div class='text-xs md:text-center font-normal md:tracking-normal'>
                    Days
                  </div>
                </span>
                <span class='md:text-[34px] text-[14px] text-white pt-3 mx-1 md:mx-[10px] font-medium'>
                  :
                </span>
                <span class='md:text-[44px] text-[24px] text-white font-bold md:tracking-[10px]'>
                  <div>{time.hours} </div>
                  <div class='text-xs text-center font-normal tracking-normal'>
                    Hours
                  </div>
                </span>
                <span class='md:text-[34px] text-[14px] text-white pt-3 mr-1 md:mx-[10px] font-medium'>
                  :
                </span>
                <span class='md:text-[44px] text-[24px] text-white font-bold md:tracking-[10px]'>
                  <div>{time.minutes} </div>
                  <div class='md:tracking-normal text-xs text-center font-normal'>
                    Minutes
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>

        <Box account={account} indexxFortuneLottery={indexxFortuneLottery} />
        <Section2 web3Handler={web3Handler} />
        <Section3 indexxFortuneLottery={indexxFortuneLottery} />
      </div>
      <DisplayMessage
        title={title}
        isVisible={isModalOpen}
        message={msg}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
};

export default Home;
